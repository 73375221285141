import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import BusinessIcon from "@mui/icons-material/Business";
import { ReactComponent as FSHIcon } from "../../assets/SVG/FILMHS-02.svg";
import { FBIcon } from "../../assets/SVG/FB-Icon";
import { LinkedinIcon } from "../../assets/SVG/Linkedin-Icon";
// import { InstagramIcon } from "../../assets/SVG/Instagram-Icon";
import { Link } from "react-router-dom";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const employerData = [
  {
    page: "Actors",
    path: "/actors",
  },
  {
    page: "Film and TV Productions",
    path: "/film&tv-production",
  },
  {
    page: "Theatre Professionals",
    path: "/theatre-professionals",
  },
  {
    page: "Voiceover Artists",
    path: "/voiceover-artist",
  },
  {
    page: "Singers",
    path: "/singers",
  },
  {
    page: "Dancers",
    path: "/dancers",
  },
  {
    page: "Music Professionals",
    path: "/music-professionals",
  },
  {
    page: "Child Actors",
    path: "/child-actors",
  },
  {
    page: "Talent",
    path: "/talent",
  },
  {
    page: "Entertainers",
    path: "/entertainers",
  },
];
const companyData = [
  {
    page: "About Us",
    path: "/about-us",
  },
  {
    page: "Career",
    path: "/careers",
  },
  {
    page: "Privacy Policy",
    path: "/privacy-policy",
  },
  {
    page: "Terms & Conditions",
    path: "/terms&conditions",
  },
  {
    page: "Contact Us",
    path: "/contact-us",
  },
  {
    page: "Help",
    path: "/help",
  },
];

const Footer = () => {
  return (
    <>
      <Grid
        container
        sx={{
          py: { md: "3rem", sm: "4rem", xs: "2rem" },
          mt: { md: "4rem", sm: "3rem", xs: "2rem" },
        }}
        className="secondary-bg-color "
      >
        <Grid
          item
          // xl={4.5}
          lg={6}
          xs={12}
          px={{ md: "50px", xs: "30px", sm: "20px" }}
        >
          <Stack rowGap={2}>
            <Box textAlign={{ md: "start", xs: "center" }}>
              <FSHIcon width={200} />
            </Box>
            <Typography
              className="font-family-lato text-color heading-20 font-weight-400"
              textAlign="justify"
            >
              In an ever-evolving landscape, the film industry stands at the
              forefront of innovation. As technology continues to redefine
              storytelling, filmmakers find themselves navigating the exciting
              digital revolution. From cutting-edge CGI to immersive virtual
              production techniques, the possibilities are limitless. Our film
              industry website is your gateway to staying informed and inspired
              by the latest trends, tools, and talents that shape the future of
              cinema.
            </Typography>
            <Box textAlign={{ md: "start", xs: "center" }}>
              <Typography
                justifyContent={{ md: "start", xs: "center" }}
                className="font-family-plus primary-color heading-20 font-weight-600 flex align-center"
              >
                <MailOutlineIcon className="label-color" /> &#160; Email:
              </Typography>
              <Typography className="font-family-lato text-color sub-heading font-weight-400">
                info@filmhelpsquad.co.uk
              </Typography>
            </Box>
            <Box textAlign={{ md: "start", xs: "center" }}>
              <Typography
                justifyContent={{ md: "start", xs: "center" }}
                className="font-family-plus primary-color heading-20 font-weight-600 flex align-center"
              >
                <BusinessIcon className="label-color" /> &#160; Address:
              </Typography>
              <Typography className="font-family-lato text-color sub-heading font-weight-400">
                International House, 36-38 Cornhill, London, England, EC3V 3NG
              </Typography>
            </Box>
            <Stack
              direction="row"
              gap={{ md: 3, xs: 5 }}
              justifyContent={{ md: "left", xs: "center" }}
            >
              <a
                href="https://www.facebook.com/people/Filmhelp-Squad/pfbid0M6GdUa4Ass4bZvAjFVbY5ZUfwwfxxh66LmRGfp4ui1JevrR3nvYJHjXBob8o2D7Ml/"
                rel="noreferrer"
                target="_blank"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://twitter.com/i/flow/login?redirect_after_login=%2Ffilmhelpsquad"
                rel="noreferrer"
                target="_blank"
              >
                <XIcon />
              </a>
              <a
                href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFPCnS13q-aawAAAYp1WgNgiuWweJnQWDq6isUweyhqWXm9j0uUzSMV3NfyONP7JAGu4Por8rLzBAD0VIMrr6c9R7tpz4FIB7RTa1EchmWYX4do1e4cEHa3jJo7YUIiyzCpL-A=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Ffilm-help-squad-034a9928b%2F"
                rel="noreferrer"
                target="_blank"
              >
                <LinkedInIcon />
              </a>
              <a
                href="https://www.instagram.com/filmhelpsquad/?next=%2F"
                rel="noreferrer"
                target="_blank"
              >
                <InstagramIcon />
              </a>
            </Stack>
          </Stack>
        </Grid>

        <Grid
          item
          lg={3}
          sm={5}
          xs={12}
          px={{ md: "50px", xs: "30px", sm: "20px" }}
          sx={{ gap: "16px", mt: { xs: "20px", sm: 0 } }}
          className="flex flex-column"
        >
          <Typography className="primary-color heading-30 font-family-dm font-weight-400">
            For Employers
          </Typography>
          <Box sx={{ gap: "12px" }} className="flex flex-column">
            {employerData.map((item, index) => (
              <Link to={item.path} key={index}>
                <li
                  className="text-color heading-20 cursor-pointer"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {item.page}
                </li>
              </Link>
            ))}
          </Box>
        </Grid>

        <Grid
          item
          lg={3}
          sm={5}
          xs={12}
          px={{ md: "50px", xs: "30px", sm: "20px" }}
          sx={{ gap: "16px", mt: { xs: "20px", sm: 0 } }}
          className="flex flex-column"
        >
          <Typography className="primary-color heading-30 font-family-dm font-weight-400">
            Company
          </Typography>
          <Box sx={{ gap: "12px" }} className="flex flex-column">
            {companyData.map((item, index) => (
              <Link to={item.path} key={index}>
                <li className="text-color heading-20 cursor-pointer">
                  {item.page}
                </li>
              </Link>
            ))}
          </Box>
        </Grid>
      </Grid>
      <div className="flex justify-center heading-20 font-family-lato text-color text-align-c">
        <Typography sx={{ py: "20px" }}>
          © Copyright 2023. Film Help Squad. All Rights Reserved
        </Typography>
      </div>
    </>
  );
};

export default Footer;
