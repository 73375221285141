import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const SecTwo = ({ imgData }) => {
  return (
    <Grid container className="justify-center" gap={7} id="professionalData">
      {imgData.map((item, index) => (
        <Grid
          item
          md={3}
          sm={5}
          sx={{ position: "relative", mx: { sm: 0, xs: "2rem" } }}
          key={item?.imgName}
          className="s2-img-container s2-img-hover-zoom border-radius-8"
        >
          <img src={item?.img} alt="profile" width={"100%"} />
          <Box className="s2-overlay cursor-pointer">
            <Typography className="s2-img-text heading-30 font-weight-400 font-family-dm white-color center-text">
              {item?.imgName}
              <br />
              <Typography className="sub-heading" component="span">
                {item?.imgDecs}
              </Typography>
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default SecTwo;
