import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Img1 from "../../../assets/Images/professionals/Image1.png";
import Img2 from "../../../assets/Images/professionals/Image2.png";
import Img3 from "../../../assets/Images/professionals/Image3.png";

const imgData = [
  {
    img: Img1,
    imgName: "Laura Beckford",
    imgDecs:
      "Laura Beckford, a 23-year-old actress, has carved out a niche in horror and sci-fi films. Her memorable roles in 'Haunted Nights', 'Future Unknown', and 'Echoes from Beyond' have captivated audiences. Julio's performances have been recognised with accolades like Best Actress at the Mexican Ariel Awards. she is a rising star in the genre film industry.",
  },
  {
    img: Img2,
    imgName: "Azam, Actor",
    imgDecs:
      "Azam, a 29-year-old actor, is renowned for his intense performances in drama and thriller films. His notable works include 'Mystery of the Past', 'Chase', and 'The Verdict'. Azam's compelling portrayals have garnered him numerous awards, such as Best Actor at the Filmfare Awards and Best Actor in a Leading Role at the National Film Awards",
  },
  {
    img: Img3,
    imgName: "Joshua, Actor",
    imgDecs: `Joshua, a 25-year-old actor, has made a significant impact in the film industry, particularly known for his roles in action and drama genres. His standout performances in films like "Hero's Journey", "City Under Siege", and "Legacy of the Brave" have earned him widespread acclaim. Joshua’s talent has been recognised with prestigious awards, including the Best Actor at the Academy Awards.`,
  },
];
const Profiles = () => {
  const lineStyle = {
    width: "191px",
    height: "3px",
    backgroundColor: "#DECDC6",
  };
  return (
    <>
      <Box
        className="flex flex-column align-center center-text"
        sx={{ px: { md: "0", xs: "2rem" }, gap: 2 }}
      >
        <Typography className="font-family-dm dm-color font-weight-400 heading-40">
          Check out the Profiles of our Acting Squad
        </Typography>
        <div style={lineStyle}></div>
        <Typography className="font-family-lato text-color font-weight-400 heading-20">
          Where your cinematic dreams get the expert guidance they deserve!
          varius sapien.
        </Typography>
      </Box>
      <Grid container className="justify-center margin-top-3" gap={7}>
        {imgData.map((item, index) => (
          <Grid
            item
            lg={3}
            sm={5}
            sx={{ position: "relative", mx: { sm: 0, xs: "2rem" } }}
            key={index}
          >
            <img src={item?.img} alt="profile" width={"100%"} />
            <Box
              sx={{
                borderRadius: "20px",
                bgcolor: "rgba(238, 238, 238, 0.1)",
                width: "80%",
                p: "24px",
                position: "absolute",
                bottom: "5%",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Typography className="font-weight-400 heading-25 font-family-dm secondary-color">
                {item?.imgName}
              </Typography>
              <Typography
                sx={{ mt: { lg: "12px", xs: "6px" } }}
                className="sub-heading font-weight-400 font-family-lato secondary-color"
              >
                {item?.imgDecs}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Profiles;
