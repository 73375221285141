// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { FormLabel, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// ----------------------------------------------------------------------

export function DateRangePicker({
  name,
  label,
  outerLabel,
  disabled,
  rules,
  ...other
}) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        return (
          <Stack gap="0.6rem">
            {label && (
              <label
                style={{
                  color: disabled ? "#4B5563" : "#4B5563",
                  fontSize: "14px",
                  lineHeight: "20PX",
                  fontWeight: "400",
                  textTransform: "capitalize",
                  marginBottom: "5px",
                }}
                className="label-color secondary-title font-family-lato font-weight-500"
              >
                {label} {<span className="error-color">*</span>}
              </label>
            )}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                {...field}
                {...other}
                slotProps={{
                  textField: {
                    helperText: error ? error.message : "",
                    error: Boolean(error),
                    fullWidth: other.fullWidth,
                    size: other.size,
                    variant: "outlined",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    height: "45px",
                    "&:hover fieldset": {
                      border: "2px solid rgb(207,242,237) !important",
                      borderColor: " rgb(207,242,237) !important",
                    },
                  },
                  "& .MuiOutlinedInput-input::placeholder": {
                    color: "#9CA3AF",
                    fontWeight: "400 !important",
                    fontSize: "14px !important",
                    lineHeight: "20px",
                    letterSpacing: "-0.28px",
                    fontFamily: "Lato, sans-serif !important",
                  },

                  "& .Mui-focused fieldset": {
                    border: "2px solid rgb(207,242,237) !important",
                    borderColor: " rgb(207,242,237) !important",
                    boxShadow: "0 0 0 0.25rem rgba(207,242,237,.4)",
                    outline: 0,
                  },
                }}
                // label={label}
              />
            </LocalizationProvider>
          </Stack>
        );
      }}
    />
  );
}
