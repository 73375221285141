import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextField } from "@mui/material";

export default function CustomTextField({
  disabled,
  multiline,
  label,
  placeholder,
  rules,
  name,
  ...other
}) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <>
          {label && (
            <label
              style={{
                color: disabled ? "#4B5563" : "#4B5563",
                fontSize: "14px",
                lineHeight: "20PX",
                fontWeight: "400",
                textTransform: "capitalize",
                marginBottom: "5px",
              }}
              className="label-color secondary-title font-family-lato font-weight-500"
            >
              {label} { <span className="error-color">*</span>}
            </label>
          )}
          <TextField
          disabled={disabled}
            {...field}
            error={!!error}
            helperText={error?.message}
            {...other}
            autoComplete="off"
            multiline={multiline}
            inputProps={{
              style: {
                color: "#224957",
                fontSize: "14px",
                lineHeight: "20PX",
                fontWeight: "400",
              },
              placeholder: placeholder,
              rows: other.minRows || 3,
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                height: multiline ? "none" : "45px",
                "&:hover fieldset": {
                  border: "2px solid rgb(207,242,237) !important",
                  borderColor: " rgb(207,242,237) !important",
                },
              },
              "& .MuiOutlinedInput-input::placeholder": {
                color: "#9CA3AF",
                fontWeight: "400 !important",
                fontSize: "14px !important",
                lineHeight: "20px",
                letterSpacing: "-0.28px",
                fontFamily: "Lato, sans-serif !important",
              },

              "& .Mui-focused fieldset": {
                border: "2px solid rgb(207,242,237) !important",
                borderColor: " rgb(207,242,237) !important",
                boxShadow: "0 0 0 0.25rem rgba(207,242,237,.4)",
                outline: 0,
              },
            }}
          />
        </>
      )}
    />
  );
}
