// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Lato", "Plus Jakarta Sans", "Open Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,iEAAA;AACF;;AAEA;EACE,uBAAA;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: 'Lato', 'Plus Jakarta Sans', 'Open Sans', sans-serif;\n}\n\nhtml {\n  scroll-behavior: smooth;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
