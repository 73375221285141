import React from "react";
import SecOne from "./components/SecOne";
import { Box } from "@mui/material";

const Professionals = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { md: "7rem", xs: "4rem" },
      }}
    >
      <SecOne />
    </Box>
  );
};

export default Professionals;
