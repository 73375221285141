import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Grid, Card, Typography, Button } from "@mui/material";
import { Container } from "@mui/material";
import CustomTextField from "../../components/hook-form/custom-text-field/CustomTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const ContactUs = () => {
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(
      Yup.object({
        name: Yup.string()
          .trim()
          .nullable()
          .defined()
          .required("Name is required")
          .matches(
            /^(?!\s*$)[A-Za-z\s]*$/,
            "Numeric values, only spaces and special characters are not allowed"
          ),
        email: Yup.string()
          .required("Email is required")
          .matches(
            /^[^\s@]+@[^\s@]+\.(?:com|[a-zA-Z]{2,})$/,
            "Invalid email address"
          ),
        phoneNumber: Yup.string()
          .trim()
          .typeError("Must be a number")
          .min(10, "Invalid Phone Number")
          .matches(/^\+44\d{10}$/, "Invalid Phone Number")
          .required("Phone Number is Required"),
        query: Yup.string()
          .trim()
          .matches(
            /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/,
            "Special characters are not allowed"
          )
          .required("Query is required"),
      })
    ),
    defaultValues: {
      name: "",
      email: "",
      phoneNumber: "",
      query: "",
    },
  });

  const onSubmit = async (formValues) => {
    try {
      const response = await fetch(
        "https://gateway-stg.airapplecart.co.uk/enquiries",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key":
              "16bd18c144d92cdc4c0765ceabb116e6:b662a149bf1c5e368c3478be501e291f8e0c0fba9b18198d8a5ece91cf3814dc738f5996420d020ee15cd83138a9f2466ebb2699b6e6efc33f0c5efe2f64b153c2a0d8a3f6ca98704a90e00db61785e8eb0def91a005606e128b991f6bb41770",
          },
          body: JSON.stringify(formValues),
        }
      );

      const responseData = await response.json();

      if (responseData.statusCode === 201) {
        toast.success(responseData?.message ?? `Thanks for contacting us.`);
        navigate("/");
        methods.reset();
      } else {
        toast.error(responseData.message || responseData.errors[0]);
      }
    } catch (error) {
      const errMsg = error?.message;
      toast.error(errMsg);
    }
  };

  const lineStyle = {
    width: "191px",
    borderBottom: "3px solid #DECDC6",
  };
  return (
    <Container
      className="flex justify-center align-center"
      sx={{ mt: { md: "6rem", sm: "3rem", xs: "2rem" } }}
    >
      <Card
        sx={{
          maxWidth: "800px",
          p: { md: "60px", sm: "30px", xs: "20px" },
          boxShadow: "0px 31.00949px 64px 0px rgba(0, 0, 0, 0.08)",
          borderRadius: "12px",
        }}
      >
        <Box
          mb={3}
          className="flex flex-column align-center center-text"
          gap={2}
        >
          <Typography className="font-family-dm dm-color font-weight-400 heading-30">
            Contact Us
          </Typography>
          <Typography className="justify-c-center">
            <div style={lineStyle}></div>
          </Typography>

          <Typography className="text-color center-text font-family-lato font-weight-400 heading-20">
            Let's Connect and Collaborate! Reach out to us with your inquiries,
            feedback, or partnership opportunities.
          </Typography>
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
                justifyContent={"center"}
                className="margin-top-0-2"
              >
                <Grid item xs={12}>
                  <CustomTextField
                    fullWidth
                    name="name"
                    label="Name"
                    placeholder="Enter Your Name"
                  />
                </Grid>

                <Grid item md={6} lg={6} xl={6} xs={12}>
                  <CustomTextField
                    fullWidth
                    name="email"
                    label="Email"
                    placeholder="Enter Email"
                  />
                </Grid>
                <Grid item md={6} lg={6} xl={6} xs={12}>
                  <CustomTextField
                    fullWidth
                    name="phoneNumber"
                    label="Phone Number"
                    placeholder="Enter Phone Number"
                  />
                </Grid>

                <Grid item md={12} lg={12} xl={12} xs={12}>
                  <CustomTextField
                    fullWidth
                    name="query"
                    label="Query"
                    placeholder="Enter Query Here..."
                    multiline="true"
                    minRows="3"
                  />
                </Grid>

                <Grid
                  mt={2}
                  item
                  xl={12}
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Button
                    type="submit"
                    sx={{ py: "10px" }}
                    className="width-100 margin-top-0 bg-primary-color white-color font-family-plus sub-heading font-weight-500 border-radius-8"
                  >
                    Send message
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </FormProvider>
      </Card>
    </Container>
  );
};
export default ContactUs;
