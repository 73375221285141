import React from "react";
import Footer from "../components/footer/Footer";
import { AppHeader } from "../components/header/app-header";
import { Outlet, useLocation } from "react-router-dom";

export const Layout = ({ userId, setUserData }) => {
  const location = useLocation();

  const isUnderConstruction = location.pathname === "/under-construction";
  return (
    <div>
      {!isUnderConstruction && (
        <AppHeader userId={userId} setUserData={setUserData} />
      )}
      <main>
        <Outlet />
      </main>
      {!isUnderConstruction && <Footer />}
    </div>
  );
};
