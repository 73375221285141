import React, { useState } from "react";
import { Grid, Typography, Box, Button, Slider } from "@mui/material";
import { ReactComponent as SearchIcon } from "../../../assets/SVG/Search-Icon.svg";
import { CustomTextField } from "../../../components/custom-text-field/custom-text-field";
import { GlobalSocialIcons } from "../../../components/social-icons/social-icons";
import SecTwo from "./SecTwo";
import Img1 from "../../../assets/Images/agents/Christina Wocintech.jpg";
import Img2 from "../../../assets/Images/agents/Gordon Cowie.jpg";
import Img3 from "../../../assets/Images/agents/Jonas Kakaroto.jpg";
import Img4 from "../../../assets/Images/agents/Joseph Gonzalez.jpg";
import Img5 from "../../../assets/Images/agents/Philip Martin.jpg";
import Img6 from "../../../assets/Images/agents/Rafaella Mendes.jpg";
import Img7 from "../../../assets/Images/agents/Stephanie Liverani.jpg";
import Img8 from "../../../assets/Images/agents/Toa Heftiba.jpg";

const SecOne = () => {
  const [value, setValue] = useState([18, 47]);
  const [name, setName] = useState("");
  const [miles, setMiles] = useState("");
  const [location, setLocation] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const imgData = [
    {
      img: Img1,
      imgName: "Christina Wocintech",
      imgDecs: "Female / Durham",
      age: 25,
      mile: "2",
    },
    {
      img: Img2,
      imgName: "Gordon Cowie",
      imgDecs: "Male / Belfast",
      age: 32,
      mile: "3",
    },
    {
      img: Img3,
      imgName: "Joshua Glenister",
      imgDecs: "Male / Wakefield",
      age: 50,
      mile: "4",
    },
    {
      img: Img4,
      imgName: "Sadie Townsend",
      imgDecs: "Male / Preston",
      age: 29,
      mile: "5",
    },

    {
      img: Img5,
      imgName: "Harrie Wenham",
      imgDecs: "Male / Exeter",
      age: 27,
      mile: "6",
    },
    {
      img: Img6,
      imgName: "Kahmal Sealey",
      imgDecs: "Female / Leeds",
      age: 22,
      mile: "7",
    },
    {
      img: Img7,
      imgName: "Amirhossein Soltani",
      imgDecs: "Female / Hereford",
      age: 35,
      mile: "8",
    },
    {
      img: Img8,
      imgName: "Angelika Agibalova",
      imgDecs: "Female / cambridge",
      age: 20,
      mile: "9",
    },
  ];
  function valuetext(value) {
    return `${value}°C`;
  }
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleMilesChange = (event) => {
    setMiles(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const filterData = (name, miles, location, value) => {
    return imgData.filter(
      (item) =>
        (name !== "" &&
          item.imgName.toLowerCase().includes(name.toLowerCase())) ||
        (miles !== "" && item.mile === miles) ||
        (location !== "" &&
          item.imgDecs.toLowerCase().includes(location.toLowerCase())) ||
        (item.age >= value[0] && item.age <= value[1])
    );
  };
  const handleSearch = () => {
    const results = filterData(name, miles, location, value);
    setSearchResults(results);
  };
  const handleClear = () => {
    setName("");
    setMiles("");
    setLocation("");
    setSearchResults([]);
    setValue([18, 47]);
  };
  return (
    <>
      <Grid
        container
        sx={{
          pt: { lg: "2rem", xs: "1rem" },
          pb: { lg: "2rem", xs: "1rem" },
          flexDirection: { lg: "row", xs: "column" },
          px: { md: "", xs: "1rem" },
        }}
        className="primary-bg-color justify-center align-center"
      >
        <Grid
          container
          item
          md={11}
          xs={12}
          className="flex flex-column align-center justify-center"
          sx={{ gap: { lg: 4, xs: 2 }, maxWidth: "1169px" }}
        >
          <Box>
            <Typography className="font-weight-400 font-family-dm white-color center-text heading-60">
              Search Agent Directory
            </Typography>
            <Typography
              maxWidth={"1169px"}
              className="heading-20 font-weight-400 font-family-lato secondary-color center-text margin-top-1"
            >
              Use the search engine to quickly browse through the network
            </Typography>
          </Box>
          <Grid item xs={6}>
            <CustomTextField
              name="name"
              EndIcon={<SearchIcon />}
              fullWidth
              placeholder="Search By Name"
              value={name}
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name="miles"
              fullWidth
              placeholder="Search By Miles"
              EndIcon={<SearchIcon />}
              value={miles}
              onChange={handleMilesChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              name="location"
              EndIcon={<SearchIcon />}
              fullWidth
              placeholder="Search By Location "
              value={location}
              onChange={handleLocationChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className="white-color heading-20 font-weight-400 font-family-lato margin-top-1 center-text">
              Playing Age: {value[0]} - {value[1]}
            </Typography>
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={value}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              sx={{
                "& .MuiSlider-rail": {
                  color: "#fff !important",
                  height: "8px",
                },
                "& .MuiSlider-track": {
                  color: "#fff !important",
                },
                "& .MuiSlider-thumbColorPrimary": {
                  color: "#fff !important",
                },
                "& .MuiSlider-valueLabel": {
                  display: "none",
                },
              }}
            />
          </Grid>
          <Grid
            item
            className="flex margin-top-1 justify-center flex-wrap"
            gap={{ sm: 2, xs: 1 }}
          >
            <Button
              sx={{
                p: "12px 18px",
                border: "1px solid #fff",
                width: { sm: "145px", xs: "120px" },
              }}
              className="bg-none white-color sub-heading font-family-plus border-radius-5"
              onClick={handleSearch}
              href="#agentsData"
            >
              Search
            </Button>
            <Button
              sx={{ p: "12px 18px", width: { sm: "145px", xs: "fit-content" } }}
              className="bg-white-color primary-color sub-heading font-family-plus border-radius-5"
              onClick={handleClear}
            >
              Clear Filters
            </Button>
          </Grid>
        </Grid>
        <Grid item lg={1} xs={12}>
          <GlobalSocialIcons />
        </Grid>
      </Grid>

      <SecTwo imgData={searchResults.length > 0 ? searchResults : imgData} />
    </>
  );
};

export default SecOne;
