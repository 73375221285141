import { AppBar, Box, Button, Grid, Toolbar } from "@mui/material";
import { ReactComponent as FHSLogo } from "../../assets/SVG/FILMHS-01.svg";
import { LinkDropdown } from "./link-dropdown/link-dropdown";
import { APPHEADERDATA } from "./app-header-data";
import { ResponsiveHeader } from "./responsive-header/responsive-header";
import { useLocation, NavLink, Link, useNavigate } from "react-router-dom";
import "./app-header.scss";
import { useState } from "react";
import toast from "react-hot-toast";

export const AppHeader = ({ userId, setUserData }) => {
  const [state, setState] = useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setState(true);
  };
  const location = useLocation()
    ?.pathname?.split("/")
    ?.filter((n) => n);
  const token =
    typeof localStorage !== "undefined"
      ? localStorage.getItem("loginToken") || null
      : null;

  const handleLogout = () => {
    // Clear user data and token from localStorage
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    // Reset user state
    setUserData({});
    // Navigate to login page
    navigate(`/sign-in`);
    toast.success("Logged out successfully");
  };
  return (
    <Box className="fhs-header" sx={{ flexGrow: 1 }}>
      <AppBar
        position="relative"
        elevation={0}
        sx={{ bgcolor: "#151C48", py: "18px", zIndex: 5 }}
      >
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xl={2.5} lg={1.5} sx={{ ml: { xl: "4rem", lg: "0" } }}>
              <Link to="/">
                <FHSLogo width={"10rem"} />
              </Link>
            </Grid>
            <Grid
              className="links align-center justify-space-between"
              sx={{
                display: { lg: "flex", xs: "none" },
                height: "48px",
              }}
              item
              xl={5.9}
              lg={7.3}
            >
              {APPHEADERDATA.map((item, index) => (
                <NavLink
                  className={({ isActive }) => {
                    if ((isActive && location[0]) === item.parentRoute) {
                      return "nav-link active";
                    }
                    if (location.length === 0 && item.href === "/") {
                      return "nav-link active";
                    }
                    return "nav-link";
                  }}
                  to={item.href}
                >
                  <LinkDropdown key={index} menu={item} />
                </NavLink>
              ))}
            </Grid>
            <Grid
              sx={{
                display: { lg: "flex", xs: "none" },
                mr: { xl: "4rem", lg: "0" },
              }}
              item
              className="links align-center justify-end"
              xl={2.5}
              lg={2}
            >
              {!userId ? (
                <NavLink
                  to={
                    token
                      ? "/under-construction"
                      : "/professionals/create-profile"
                  }
                >
                  <p
                    style={{ whiteSpace: "nowrap" }}
                    className="cursor-pointer margin-right-1 align-center color-white font-family-lato font-weight-400 heading-20"
                  >
                    Sign Up
                  </p>
                </NavLink>
              ) : null}
              {!userId ? (
                <NavLink to={token ? "/under-construction" : "/sign-in"}>
                  <Button
                    className="primary-bg-color flex sub-heading font-weight-500"
                    sx={{
                      p: "12px 18px",
                      borderRadius: "5.5px",
                      border: "1px solid #fff",
                      width: "100px",
                    }}
                    variant="contained"
                  >
                    Login
                  </Button>
                </NavLink>
              ) : (
                <Button
                  className="primary-bg-color flex sub-heading font-weight-500"
                  sx={{
                    p: "12px 18px",
                    borderRadius: "5.5px",
                    border: "1px solid #fff",
                    width: "100px",
                  }}
                  variant="contained"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              )}
            </Grid>
            <Box
              className="links align-center justify-space-between"
              sx={{ display: { lg: "none", xs: "flex" } }}
            >
              <span id="menu-icon" title="Menu" onClick={handleDrawerOpen}>
                <span class="menu-line menu-line-1"></span>
                <span class="menu-line menu-line-2"></span>
                <span class="menu-line menu-line-3"></span>
              </span>
              <ResponsiveHeader state={state} setState={setState} />
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
