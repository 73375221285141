import React, { useState } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import { ReactComponent as SearchIcon } from "../../../assets/SVG/Search-Icon.svg";
import { CustomTextField } from "../../../components/custom-text-field/custom-text-field";
import { GlobalSocialIcons } from "../../../components/social-icons/social-icons";
import SecTwo from "../components/SecTwo";

const SecOne = () => {
  const [name, setName] = useState("");
  const [miles, setMiles] = useState("");
  const [location, setLocation] = useState("");
  const [categories, setCategories] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const tableData = [
    {
      company: "BBC",
      category: "Film and TV Production Company",
      users: "28,251",
      location: "England",
      mile: "1",
    },
    {
      company: "ITV",
      category: "Film and TV Production Company",
      users: "9,672",
      bgcolor: "#F8F5F4",
      location: "England",
      mile: "2",
    },
    {
      company: "East 15 Acting School",
      category: "Training",
      users: "6,291",
      location: "England",
      mile: "3",
    },
    {
      company: "Mount view",
      category: "Training",
      users: "5,051",
      bgcolor: "#F8F5F4",
      location: "England",
      mile: "4",
    },
    {
      company: "London Film School",
      category: "Training",
      users: "4,246",
      location: "England",
      mile: "4",
    },
    {
      company: "Drama Studio London",
      category: "Training, education, drama school",
      users: "3,858",
      bgcolor: "#F8F5F4",
      location: "England",
      mile: "5",
    },
    {
      company: "Warner Bros",
      category: "Production Company",
      users: "3,552",
      location: "England",
      mile: "6",
    },
    {
      company: "CSSD",
      category: "Training",
      users: "3,391",
      bgcolor: "#F8F5F4",
      location: "England",
      mile: "7",
    },
    {
      company: "BBC Radio",
      category: "Radio",
      users: "3,300",
      location: "London",
      mile: "8",
    },
  ];
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleMilesChange = (event) => {
    setMiles(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleCategoriesChange = (event) => {
    setCategories(event.target.value);
  };

  const filterData = ({ company, category, location, miles }) => {
    return tableData.filter(
      (item) =>
        (company !== "" &&
          item.company.toLowerCase().includes(company.toLowerCase())) ||
        (category !== "" &&
          item.category.toLowerCase().includes(category.toLowerCase())) ||
        (location !== "" &&
          item.location.toLowerCase().includes(location.toLowerCase())) ||
        (miles !== "" && item.mile === miles)
    );
  };
  const handleSearch = () => {
    const results = filterData({
      company: name,
      category: categories,
      location,
      miles: miles,
    });
    setSearchResults(results);
  };
  const handleClear = () => {
    setName("");
    setMiles("");
    setLocation("");
    setCategories("");
    setSearchResults([]);
  };
  return (
    <>
      <Grid
        container
        sx={{
          pt: { lg: "2rem", xs: "1rem" },
          pb: { lg: "2rem", xs: "1rem" },
          flexDirection: { lg: "row", xs: "column" },
          px: { md: "", xs: "1rem" },
        }}
        className="primary-bg-color justify-center align-center"
      >
        <Grid
          container
          item
          md={11}
          xs={12}
          className="flex flex-column align-center justify-center"
          sx={{ gap: { lg: 4, xs: 2 }, maxWidth: "1169px" }}
        >
          <Box>
            <Typography className="font-weight-400 font-family-dm white-color center-text heading-60">
              Search Employee Directory
            </Typography>
            <Typography
              maxWidth={"1169px"}
              className="heading-20 font-weight-400 font-family-lato secondary-color center-text margin-top-1"
            >
              Use the search engine to quickly browse through the network
            </Typography>
          </Box>
          <Grid item xs={6}>
            <CustomTextField
              name="name"
              EndIcon={<SearchIcon />}
              fullWidth
              placeholder="Search By Name"
              value={name}
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name="categories"
              EndIcon={<SearchIcon />}
              fullWidth
              placeholder="Search By category"
              value={categories}
              onChange={handleCategoriesChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name="miles"
              fullWidth
              placeholder="Search By Miles"
              EndIcon={<SearchIcon />}
              value={miles}
              onChange={handleMilesChange}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              name="location"
              EndIcon={<SearchIcon />}
              fullWidth
              placeholder="Search By Location "
              value={location}
              onChange={handleLocationChange}
            />
          </Grid>
          <Grid
            item
            className="flex margin-top-1 justify-center flex-wrap"
            gap={{ sm: 2, xs: 1 }}
          >
            <Button
              sx={{
                p: "12px 18px",
                border: "1px solid #fff",
                width: { sm: "145px", xs: "120px" },
              }}
              className="bg-none white-color sub-heading font-family-plus border-radius-5"
              onClick={handleSearch}
              href="#employerData"
            >
              Search
            </Button>
            <Button
              sx={{ p: "12px 18px", width: { sm: "145px", xs: "fit-content" } }}
              className="bg-white-color primary-color sub-heading font-family-plus border-radius-5"
              onClick={handleClear}
            >
              Clear Filters
            </Button>
          </Grid>
        </Grid>
        <Grid item lg={1} xs={12}>
          <GlobalSocialIcons />
        </Grid>
      </Grid>
      <SecTwo
        tableData={searchResults.length > 0 ? searchResults : tableData}
      />
    </>
  );
};

export default SecOne;
