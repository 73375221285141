"use client";

// import { bgImage } from "@assets/images";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTimer } from "react-timer-hook";

export function VerificationTimer({ userData }) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("userId");
  const initialTimerSeconds = 600;

  const { isRunning, seconds, restart, minutes } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => {
      setIsButtonDisabled(false);
    },
  });

  const formattedSeconds = String(seconds).padStart(2, "0");

  const handleResendClick = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}auth/resend-verificaiton-link`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: userId }),
        }
      );

      const responseData = await response.json();
      restart(new Date().getTime() + initialTimerSeconds * 100);
      toast.success(responseData?.message ?? `Check Your Email.`);
    } catch (error) {
      const errMsg = error?.message;
      toast.error(errMsg);
    }
  }, [restart, userId]);
  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  useEffect(() => {
    if (isEmpty(userData) && !userId) {
      window.history.back();
    } else {
      setIsButtonDisabled(true);
      restart(new Date().getTime() + initialTimerSeconds * 100);
    }
  }, [restart, userData, userId]);

  return (
    <Box
      sx={{ background: "radial-gradient( #151C48, #151C48)" }}
      height={"100vh"}
    >
      <Box
        sx={{
          // maxHeight: { xs: "95dvh", sm: "75dvh" },
          //   backgroundImage: `url(${bgImage.src})`,
          backgroundPosition: "right center",
          backgroundSize: 1000,
          pt: {
            sm: 25,
            xs: 20,
          },
          pb: {
            md: 10,
            xs: 3,
          },
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            maxWidth: {
              md: "90%",
              xs: "100%",
            },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack
            alignItems="center"
            direction="column"
            spacing={0}
            textAlign="center"
          >
            {userId ? (
              <Stack
                direction="column"
                alignItems="center"
                spacing={3}
                sx={{
                  pb: {
                    md: 20,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  maxWidth={540}
                  color="common.white"
                  sx={{ lineHeight: "30px" }}
                >
                  A Final Step before your application is submitted
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    letterSpacing: "-1.2px",
                    fontWeight: 600,
                  }}
                  color="common.white"
                >
                  {`Let's Get You Verified`}
                </Typography>
                <Typography
                  variant="subtitle1"
                  maxWidth={540}
                  color="common.white"
                  sx={{ lineHeight: "30px" }}
                >
                  A Verification link has been sent to your email account.
                  Please click on that link to verify your email address.
                </Typography>
                {/* Timer component */}
                <Box
                  sx={{
                    width: "150px",
                    height: "80px",
                    backgroundColor: "common.white",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 3,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#151C48",
                      textAlign: "center",
                      fontSize: "48px",
                      fontWeight: 700,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {minutes} : {formattedSeconds}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    maxWidth={540}
                    color="common.white"
                    sx={{ lineHeight: "30px" }}
                  >
                    {`If you didn't get the verification email click on`}
                  </Typography>
                  <Button
                    variant="text"
                    disabled={isButtonDisabled || isRunning}
                    onClick={handleResendClick}
                    sx={{
                      mx: 2,
                      textDecoration: "none",
                      fontSize: "1rem",
                      fontWeight: 500,
                      backgroundColor: "common.white",
                      color: "#151C48",
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                  >
                    Resend Link
                  </Button>
                  <Typography
                    variant="subtitle1"
                    maxWidth={540}
                    color="common.white"
                    sx={{ lineHeight: "30px" }}
                  >
                    {`after timeout`}
                  </Typography>
                </Box>
              </Stack>
            ) : (
              <Typography
                variant="h5"
                maxWidth={540}
                color="common.white"
                sx={{ lineHeight: "30px" }}
              >
                Something went wrong
              </Typography>
            )}
            <Button
              className="primary-bg-color flex sub-heading font-weight-500"
              sx={{
                p: "12px 18px",
                borderRadius: "5.5px",
                border: "1px solid #fff",
                width: "100px",
              }}
              variant="contained"
              href="/"
            >
              Home
            </Button>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
