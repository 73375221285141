import React, { useEffect, useState } from "react";
import CenturyBox from "../../assets/Images/sign-in/centuryfox.png";
import Netflix from "../../assets/Images/sign-in/netflix.png";
import BBC from "../../assets/Images/sign-in/bbc.png";
import AmazonPrime from "../../assets/Images/sign-in/amazon-prime.png";
import HBO from "../../assets/Images/sign-in/hbo.png";
import Disney from "../../assets/Images/sign-in/disney.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CustomTextField from "../../components/hook-form/custom-text-field/CustomTextField";
import { useForm, FormProvider } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import toast from "react-hot-toast";

const imgData = [CenturyBox, Netflix, BBC, AmazonPrime, HBO, Disney];

const SignIn = ({ setUserData }) => {
  const [showPassword, setShowPassword] = useState(false);
  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const passwordEndAdornment = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={handleShowPassword} edge="end">
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </InputAdornment>
    ),
  };

  const navigate = useNavigate();

  const onSubmit = async (formValues) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}auth/signin`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formValues, companyShortCode: "FHS" }),
        }
      );

      const responseData = await response.json();
      if (responseData.data?.user) {
        const user = responseData.data.user;
        const token = responseData.data.token;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);

        setUserData(user);
        toast.success(responseData?.message ?? `SignIn Successfully!`);
        navigate(`/statusPage`);
        methods.reset();
      } else {
        toast.error(responseData?.message);
      }
    } catch (error) {
      const errMsg = error?.message;
      toast.error(errMsg);
    }
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUserData(JSON.parse(storedUser));
      navigate(-1); // Navigate to the previous page
    }
  }, [navigate, setUserData]);
  return (
    <Container
      className="flex flex-column justify-center align-center"
      sx={{ mt: { md: "6rem", sm: "3rem", xs: "2rem" } }}
    >
      <Card
        sx={{
          maxWidth: "460px",
          boxShadow: "0px 31.00949px 64px 0px rgba(0, 0, 0, 0.08)",
          borderRadius: "20px",
          p: { xl: "50px", xs: "10px" },
        }}
        className="width-100"
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div
              className="flex flex-column align-center"
              style={{ gap: "36px" }}
            >
              <Typography className="heading-40 font-family-dm dm-color font-weight-400">
                Login
              </Typography>

              <Box width={"100%"}>
                <Grid
                  container
                  spacing={2}
                  justifyContent={"center"}
                  className="margin-top-0-2"
                >
                  <Grid item md={12} lg={12} xl={12} xs={12}>
                    <CustomTextField
                      fullWidth
                      name="email"
                      label="Email"
                      placeholder="Enter Email"
                      rules={{
                        required: "Field is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={12} lg={12} xl={12} xs={12}>
                    <CustomTextField
                      fullWidth
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      InputProps={passwordEndAdornment}
                      placeholder="Enter Password"
                      rules={{ required: "Password is required" }}
                    />
                  </Grid>

                  <Grid
                    mt={2}
                    item
                    xl={12}
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Button
                      type="submit"
                      sx={{ py: "10px" }}
                      className="width-100 margin-top-0 bg-primary-color white-color font-family-plus sub-heading font-weight-500 border-radius-8"
                    >
                      Login
                    </Button>
                  </Grid>
                  <Grid item xl={12} xs={10}>
                    <Typography
                      variant="body1"
                      textAlign={"center"}
                      className="font-family-dm "
                    >
                      Do not have an account? &nbsp;
                      <Link
                        className="link-color font-family-dm"
                        to="/professionals/create-profile"
                        style={{ textDecoration: "none" }}
                      >
                        Sign up
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </form>
        </FormProvider>
      </Card>
      <Typography className="font-family-dm dm-color heading-40 center-text margin-top-3">
        Companies who hire with us
      </Typography>
      <Grid container gap={4} className="justify-center margin-top-2">
        {imgData.map((i, index) => (
          <img key={index} src={i} alt="" width={"auto"} />
        ))}
      </Grid>
    </Container>
  );
};

export default SignIn;
