import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

const UnderConstruction = () => {
  const navigate = useNavigate();

  const logoutHandler = () => {
    localStorage.removeItem("loginToken");
    navigate("/sign-in");
  };

  return (
    <Box
      sx={{
        height: {
          xs: "100vh",
        },
      }}
    >
      <Box
        sx={{ height: "100vh" }}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
        gap={5}
      >
        <Box
          pl={2}
          pr={2}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            mb={3}
            sx={{
              width: { xs: "100%", xl: "96%", lg: "96%", md: "97%", sm: "98%" },
              fontSize: {
                xs: "20px",
                sm: "30px",
                md: "40px",
                lg: "50px",
                xl: "55px",
              },
              fontWeight: "600",
            }}
          >
            Exciting news – our website is getting a makeover! 🛠️
          </Typography>
          <Typography
            variant="body1"
            sx={{
              width: { xs: "90%", xl: "70%", lg: "70%", md: "70%", sm: "60%" },
              whiteSpace: "pre-wrap",
              fontSize: {
                xs: "16px",
                sm: "18px",
                md: "20px",
                lg: "22px",
                xl: "25px",
              },
            }}
            textAlign={"center"}
          >
            Thanks a bunch for reaching out to us. We’ve got your info and we’re
            thrilled to connect with you soon! Our friendly representative will
            be in touch with all the awesome details and information you need.
          </Typography>
          <Typography
            mt={6}
            variant="body1"
            textAlign={"center"}
            sx={{
              fontSize: {
                xs: "16px",
                md: "18px",
                lg: "20px",
                xl: "22px",
              },
            }}
          >
            Hang tight and talk to you real soon! 🚀
          </Typography>
          <Stack mt={5} spacing={2} direction="row">
            <Link to="/">
              <Button
                startIcon={<HomeOutlinedIcon style={{ fontSize: "26px" }} />}
                type="submit"
                sx={{ py: "10px" }}
                className="width-100 margin-top-0 bg-primary-color white-color font-family-plus sub-heading font-weight-500 border-radius-8"
              >
                Home
              </Button>
            </Link>
            <Button
              onClick={logoutHandler}
              startIcon={<LogoutOutlinedIcon style={{ fontSize: "26px" }} />}
              type="submit"
              sx={{ py: "10px" }}
              className="width-100 margin-top-0 bg-primary-color white-color font-family-plus sub-heading font-weight-500 border-radius-8"
            >
              Logout
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default UnderConstruction;
