import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import CustomTextField from "../../../../components/hook-form/custom-text-field/CustomTextField";
import {
  Box,
  Grid,
  Card,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { DateRangePicker } from "../../../../components/date-picker/date-picker";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const Employer = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(
      Yup.object({
        firstName: Yup.string()
          .trim()
          .nullable()
          .defined()
          .required("First name is required")
          .matches(
            /^(?!\s*$)[A-Za-z\s]*$/,
            "Numeric values, only spaces and special characters are not allowed"
          ),
        lastName: Yup.string()
          .trim()
          .nullable()
          .defined()
          .required("Last name is required")
          .matches(
            /^(?!\s*$)[A-Za-z\s]*$/,
            "Numeric values, only spaces and special characters are not allowed"
          ),
        email: Yup.string()
          .required("Email is required")
          .matches(
            /^[^\s@]+@[^\s@]+\.(?:com|[a-zA-Z]{2,})$/,
            "Invalid email address"
          ),
        contactNumber: Yup.string()
          .required("Phone Number is Required")
          .trim()
          .typeError("Must be a number")
          .min(10, "Invalid Phone Number")
          .matches(/^\+44\d{10}$/, "Invalid Phone Number"),
        dob: Yup.date()
          .transform((value, originalValue) => {
            return originalValue === "" ? null : value;
          })
          .required("Date of Birth is required")
          .max(new Date(), "Date of Birth cannot be in the future"),
        password: Yup.string()
          .required("Password is required")
          .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long"
          ),
        companyName: Yup.string()
          .trim()
          .nullable()
          .defined()
          .required("Business name is required")
          .matches(
            /^(?!\s*$)[A-Za-z\s]*$/,
            "Numeric values, only spaces and special characters are not allowed"
          ),
        crn: Yup.string().required("Company registration number  is required"),
        address: Yup.string().required("Address is required"),
      })
    ),
    defaultValues: {
      firstName: "",
      lastName: "",
      dob: "",
      contactNumber: "",
      email: "",
      companyName: "",
      crn: "",
      address: "",
      password: "",
      companyShortCode: "FHS",
    },
  });

  const onSubmit = async (formValues) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}auth/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formValues),
        }
      );

      const responseData = await response.json();

      if (responseData?.data !== null) {
        toast.success(
          responseData?.message ?? `Sign Up Successfully!. Check Your Email.`
        );
        navigate(`/verification?userId=${responseData?.data?.userId}`);
        methods.reset();
      } else {
        toast.error(responseData.message || responseData.errors[0]);
      }
    } catch (error) {
      const errMsg = error?.message;
      toast.error(errMsg);
    }
  };

  const lineStyle = {
    width: "191px",
    borderBottom: "3px solid #DECDC6",
  };
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const passwordEndAdornment = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={handleShowPassword} edge="end">
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </InputAdornment>
    ),
  };
  return (
    <Card
      sx={{
        maxWidth: "500px",
        p: { md: "60px", sm: "30px", xs: "20px" },
        boxShadow: "0px 31.00949px 64px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "12px",
      }}
    >
      <Box mb={3} className="flex flex-column align-center center-text" gap={2}>
        <Typography className="heading-30 font-weight-400 font-family-dm dm-color text-align-c">
          Employer
        </Typography>
        <Typography className="justify-c-center">
          <div style={lineStyle}></div>
        </Typography>
        <Typography className="text-color heading-20 font-weight-400 font-family-lato text-align-c">
          Welcome to Film Help Squad employer platform! Create your account,
          showcase your identity, and let's bring your cinematic visions to
          life.
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              className="margin-top-0-2"
            >
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  name="email"
                  label="Email"
                  placeholder="Enter Email"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  name="password"
                  label="Password"
                  placeholder="Enter Password"
                  type={showPassword ? "text" : "password"}
                  InputProps={passwordEndAdornment}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  name="contactNumber"
                  label="Phone Number"
                  placeholder="Enter UK Phone Number +441234567890"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  name="companyName"
                  label="Business Name"
                  placeholder="Enter Business Name"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  name="crn"
                  label="Company Registration Number"
                  placeholder="Registration Number"
                />
              </Grid>
              <Grid item xs={12}>
                <DateRangePicker
                  fullWidth
                  name="dob"
                  label="Date of Birth"
                  require
                  disableFuture={true}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  name="address"
                  label="Address"
                  placeholder="Enter your address"
                />
              </Grid>

              <Grid
                mt={2}
                item
                xl={12}
                xs={12}
                display={"flex"}
                justifyContent={"center"}
              >
                <Button
                  type="submit"
                  sx={{ py: "10px" }}
                  className="width-100 margin-top-0 bg-primary-color white-color font-family-plus sub-heading font-weight-500 border-radius-8"
                >
                  Sign Up
                </Button>
              </Grid>
              <Typography className=" font-weight-400 font-family-plus text-align-c">
                Already have an account ?{" "}
                <Button
                  className="link-color"
                  href="/sign-in"
                  variant="text"
                  sx={{ fontWeight: "bold" }}
                >
                  Login
                </Button>
              </Typography>
              <Typography
                className=" font-weight-400 font-family-plus text-align-c"
                textAlign="center"
              >
                By continuing to create account you are agree to Film Help Squad
                <Button
                  href="/terms&conditions"
                  color="error"
                  variant="text"
                  sx={{ fontWeight: "bold" }}
                >
                  Terms & Conditions
                </Button>
                and
                <Button
                  href="/privacy-policy"
                  color="error"
                  variant="text"
                  sx={{ fontWeight: "bold" }}
                >
                  Privacy Policy
                </Button>
              </Typography>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </Card>
  );
};

export default Employer;
