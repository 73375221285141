export const LinkedinIcon = ({fill="#151C48"}) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_73_926)">
      <path
        d="M18 0.720215C8.45637 0.720215 0.719971 8.45662 0.719971 18.0002C0.719971 27.5438 8.45637 35.2802 18 35.2802C27.5436 35.2802 35.28 27.5438 35.28 18.0002C35.28 8.45662 27.5436 0.720215 18 0.720215ZM13.77 25.1624H10.2708V13.9016H13.77V25.1624ZM11.9988 12.5192C10.8936 12.5192 10.179 11.7362 10.179 10.7678C10.179 9.77961 10.9152 9.02001 12.0438 9.02001C13.1724 9.02001 13.8636 9.77961 13.8852 10.7678C13.8852 11.7362 13.1724 12.5192 11.9988 12.5192ZM26.55 25.1624H23.0508V18.9218C23.0508 17.4692 22.5432 16.4828 21.2778 16.4828C20.3112 16.4828 19.737 17.1506 19.4832 17.7932C19.3896 18.0218 19.3662 18.3458 19.3662 18.668V25.1606H15.8652V17.4926C15.8652 16.0868 15.8202 14.9114 15.7734 13.8998H18.8136L18.9738 15.464H19.044C19.5048 14.7296 20.6334 13.646 22.5216 13.646C24.8238 13.646 26.55 15.1886 26.55 18.5042V25.1624Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_73_926">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
