import { Box, Card,  Typography } from "@mui/material";
import React, { useEffect } from "react";
import SVG1 from "../assets/Images/home/section4/svg1.svg";
import SVG2 from "../assets/Images/home/section4/svg2.svg";
import { NavLink, useNavigate } from "react-router-dom";

const StatusPage = ({ userData, setUserData }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUserData(JSON.parse(storedUser));
    } else {
      navigate("/");
    }
  }, [navigate, setUserData]);

  return (
    <Card
      className="primary-bg-color"
      sx={{
        borderRadius: 20,
        width: { md: "30rem" },
        height: { md: "41rem", xs: "35rem" },
        px: 6,
        mt: 7,
        mx: { md: "auto", xs: 3 },
        boxShadow: 1,
        opacity: 0.88,
      }}
    >
      <Box textAlign="right">
        <img src={SVG2} width={"auto"} alt="" />
      </Box>
      <Typography
        className="font-weight-400 font-family-dm secondary-color center-text heading-20"
        my={2.5}
      >
        We appreciate your interest and are pleased to inform you that your
        query has been successfully submitted.
      </Typography>
      <Typography
        className="font-weight-400 font-family-dm secondary-color center-text heading-30"
        my={2.5}
      >
        Your verification status is :
        <Typography
          className="font-weight-400 font-family-dm yellow-color center-text heading-30"
          component="p"
        >
          {" "}
          {userData?.verificationStatus}
        </Typography>
      </Typography>
      <Typography
        className="secondary-color font-weight-400 font-family-lato center-text heading-20"
        my={2.5}
      >
        One of our sales agents will review your information and contact you
        shortly to assist with your request.
      </Typography>
      <Typography
        className="secondary-color font-weight-400 font-family-lato center-text heading-20"
        my={2.5}
      >
        If you have any further questions or need immediate assistance, please
        do not hesitate to reach out to us at{" "}
        <Typography
          className="secondary-color font-weight-400 font-family-lato center-text heading-20"
          component="span"
          sx={{ textDecoration: "underline", cursor: "pointer" }}
          href="/contact-us"
        >
          <NavLink to="/contact-us">Contact Us .</NavLink>
        </Typography>
      </Typography>
      <Box>
        <img src={SVG1} width={"auto"} alt="" />
      </Box>
    </Card>
  );
};

export default StatusPage;
